import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3148606"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-title" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "login-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationPasswordField = _resolveComponent("ValidationPasswordField")!
  const _component_Form = _resolveComponent("Form")!
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("login.passwordNewTagline")), 1),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("login.passwordNewTitle")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Form, {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ValidationPasswordField, {
            type: "password",
            rules: "required",
            name: "password",
            placeholder: _ctx.$t('login.passwordNewHint'),
            message: {
            error_required: _ctx.$t('errors.required'),
          }
          }, null, 8, ["placeholder", "message"]),
          _createVNode(_component_ValidationPasswordField, {
            class: "mt-2",
            type: "password",
            rules: "required",
            name: "repeatPassword",
            placeholder: _ctx.$t('login.passwordNewRetypeHint'),
            message: {
            error_required: _ctx.$t('errors.required'),
          }
          }, null, 8, ["placeholder", "message"])
        ]),
        _: 1
      }),
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('login.passwordNewButton'),
        class: "forgot-confirm-btn w-full mt-2",
        onOnClick: _ctx.submit
      }, null, 8, ["text", "onOnClick"])
    ])
  ]))
}