import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-db5a6d68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "validation-password-field" }
const _hoisted_2 = { class: "password-rule-tile" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "rule-text ml-1" }
const _hoisted_5 = { class: "password-rule-tile mt-1" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "rule-text ml-1" }
const _hoisted_8 = { class: "password-rule-tile mt-1" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "rule-text ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_MenuOptionDropdown = _resolveComponent("MenuOptionDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ValidationTextField, {
      rules: _ctx.rules,
      type: "password",
      modelValue: _ctx.val,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      "keep-error-space": false,
      "onUpdate:value": _ctx.valueChanged,
      onOnFocus: _ctx.onFocus,
      onOnBlur: _ctx.onBlur,
      message: _ctx.message
    }, null, 8, ["rules", "modelValue", "name", "placeholder", "onUpdate:value", "onOnFocus", "onOnBlur", "message"]),
    (_ctx.hasFocus)
      ? (_openBlock(), _createBlock(_component_MenuOptionDropdown, {
          key: 0,
          class: "password-hint",
          "indicator-left": "",
          "indicator-position": "10px"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: 
            _ctx.hasCharacter
              ? require('icons/check-mark-green.svg')
              : require('icons/check-mark-grey.svg')
          ,
                alt: "icon"
              }, null, 8, _hoisted_3),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("common.passwordHintCharacter")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                src: 
            _ctx.hasNumber
              ? require('icons/check-mark-green.svg')
              : require('icons/check-mark-grey.svg')
          ,
                alt: "icon"
              }, null, 8, _hoisted_6),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("common.passwordHintNumber")), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", {
                src: 
            _ctx.minimun8Chars
              ? require('icons/check-mark-green.svg')
              : require('icons/check-mark-grey.svg')
          ,
                alt: "icon"
              }, null, 8, _hoisted_9),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("common.passwordHintMin")), 1)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}