
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import MenuOptionDropdown from "@/components/atomics/dropdown/MenuOptionDropdown.vue";
import { hasNumber, hasAlphabet } from "@/lib/utility/stringUtil";
import { useField } from "vee-validate";

export default defineComponent({
  components: {
    ValidationTextField,
    MenuOptionDropdown,
  },
  name: "ValidationPasswordField",
  props: {
    name: { type: String, default: "" },
    value: { type: String, default: "" },
    message: { type: Object || String },
    placeholder: { type: String, default: "" },
    rules: { type: String, default: "" },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const { setErrors } = useField(props.name, props.rules, {
      initialValue: props.value,
    });

    const validateState = reactive({
      val: props.value,
      hasCharacter: false,
      hasNumber: false,
      minimun8Chars: false,
    });

    watch(
      () => props.value,
      (val) => {
        validateState.val = val;
        if (
          !validateState.hasCharacter ||
          !validateState.hasNumber ||
          !validateState.minimun8Chars
        ) {
          setErrors("invalidPassword");
        }
      }
    );

    const hasFocus = ref(false);

    const valueChanged = (value: string) => {
      if (hasAlphabet(value)) {
        validateState.hasCharacter = true;
      } else {
        validateState.hasCharacter = false;
      }

      if (validateState.hasCharacter && hasNumber(value)) {
        validateState.hasNumber = true;
      } else {
        validateState.hasNumber = false;
      }

      if (validateState.hasNumber && value.length >= 8) {
        validateState.minimun8Chars = true;
      } else {
        validateState.minimun8Chars = false;
      }

      emit("update:value", value);
    };

    const onFocus = () => {
      hasFocus.value = true;
    };
    const onBlur = () => {
      hasFocus.value = false;
    };

    return {
      ...toRefs(validateState),
      hasFocus,
      onFocus,
      onBlur,
      valueChanged,
    };
  },
});
