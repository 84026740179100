
import { defineComponent } from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import ValidationPasswordField from "@/components/atomics/ValidationPasswordField.vue";
import { ForgotPasswordRepository, RepositoryFactory } from "@/lib/https";
import { useRouter } from "vue-router";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";

export default defineComponent({
  name: "PasswordNew",
  components: {
    FlatButton,
    ValidationPasswordField,
  },

  props: {
    code: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const router = useRouter();

    const { updateForgotPassword } =
      RepositoryFactory.getRepository<ForgotPasswordRepository>(
        ForgotPasswordRepository
      );

    const { formHandle } = useFormHandler(
      {
        password: "",
        repeatPassword: "",
        code: props.code,
      },
      updateForgotPassword
    );

    const submit = async function () {
      const response = await formHandle();
      if (response) {
        router.push({ name: "PasswordNewSuccess" });
      }
    };

    return {
      submit,
    };
  },
});
